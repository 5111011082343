import styled from "styled-components";

const SubjectContent = styled.p`
    /* border: 1px solid green; */
    text-align: justify;
    color: rgba(0, 0, 0, 0.54);
    font-family: Avenir;
    margin: 0;

    box-sizing: border-box;
`;

export default SubjectContent;