import styled from "styled-components";

const Title = styled.div`
    /* border: 1px solid red; */
    width: 100%;
    height: 20px;

    margin-top: 16px;

    text-align: left;
    font-size: 18px;
`;

export default Title;