export const locations = [
  { id: 1, title: "Adjuntas", selected: "false", key: "location" },
  { id: 2, title: "Aguada", selected: "false", key: "location" },
  { id: 3, title: "Aguadilla", selected: "false", key: "location" },
  { id: 4, title: "Aguas Buenas", selected: "false", key: "location" },
  { id: 5, title: "Aibonito", selected: "false", key: "location" },
  { id: 6, title: "A\u00f1asco", selected: "false", key: "location" },
  { id: 7, title: "Arecibo", selected: "false", key: "location" },
  { id: 8, title: "Arroyo", selected: "false", key: "location" },
  { id: 9, title: "Barceloneta", selected: "false", key: "location" },
  { id: 10, title: "Barranquitas", selected: "false", key: "location" },
  { id: 11, title: "Bayam\u00f3n", selected: "false", key: "location" },
  { id: 12, title: "Cabo Rojo", selected: "false", key: "location" },
  { id: 13, title: "Caguas", selected: "false", key: "location" },
  { id: 14, title: "Camuy", selected: "false", key: "location" },
  { id: 15, title: "Can\u00f3vanas", selected: "false", key: "location" },
  { id: 16, title: "Carolina", selected: "false", key: "location" },
  { id: 17, title: "Cata\u00f1o", selected: "false", key: "location" },
  { id: 18, title: "Cayey", selected: "false", key: "location" },
  { id: 19, title: "Ceiba", selected: "false", key: "location" },
  { id: 20, title: "Ciales", selected: "false", key: "location" },
  { id: 21, title: "Cidra", selected: "false", key: "location" },
  { id: 22, title: "Coamo", selected: "false", key: "location" },
  { id: 23, title: "Comer\u00edo", selected: "false", key: "location" },
  { id: 24, title: "Corozal", selected: "false", key: "location" },
  { id: 25, title: "Culebra", selected: "false", key: "location" },
  { id: 26, title: "Dorado", selected: "false", key: "location" },
  { id: 27, title: "Fajardo", selected: "false", key: "location" },
  { id: 28, title: "Florida", selected: "false", key: "location" },
  { id: 29, title: "Gu\u00e1nica", selected: "false", key: "location" },
  { id: 30, title: "Guayama", selected: "false", key: "location" },
  { id: 31, title: "Guayanilla", selected: "false", key: "location" },
  { id: 32, title: "Guaynabo", selected: "false", key: "location" },
  { id: 33, title: "Gurabo", selected: "false", key: "location" },
  { id: 34, title: "Hatillo", selected: "false", key: "location" },
  { id: 35, title: "Hormigueros", selected: "false", key: "location" },
  { id: 36, title: "Humacao", selected: "false", key: "location" },
  { id: 37, title: "Isabela", selected: "false", key: "location" },
  { id: 38, title: "Jayuya", selected: "false", key: "location" },
  { id: 39, title: "Juana D\u00edaz", selected: "false", key: "location" },
  { id: 40, title: "Juncos", selected: "false", key: "location" },
  { id: 41, title: "Lajas", selected: "false", key: "location" },
  { id: 42, title: "Lares", selected: "false", key: "location" },
  { id: 43, title: "Las Mar\u00edas", selected: "false", key: "location" },
  { id: 44, title: "Las Piedras", selected: "false", key: "location" },
  { id: 45, title: "Lo\u00edza", selected: "false", key: "location" },
  { id: 46, title: "Luquillo", selected: "false", key: "location" },
  { id: 47, title: "Manat\u00ed", selected: "false", key: "location" },
  { id: 48, title: "Maricao", selected: "false", key: "location" },
  { id: 49, title: "Maunabo", selected: "false", key: "location" },
  { id: 50, title: "Mayag\u00fcez", selected: "false", key: "location" },
  { id: 51, title: "Moca", selected: "false", key: "location" },
  { id: 52, title: "Morovis", selected: "false", key: "location" },
  { id: 53, title: "Naguabo", selected: "false", key: "location" },
  { id: 54, title: "Naranjito", selected: "false", key: "location" },
  { id: 55, title: "Orocovis", selected: "false", key: "location" },
  { id: 56, title: "Patillas", selected: "false", key: "location" },
  { id: 57, title: "Pe\u00f1uelas", selected: "false", key: "location" },
  { id: 58, title: "Ponce", selected: "false", key: "location" },
  { id: 59, title: "Quebradillas", selected: "false", key: "location" },
  { id: 60, title: "Rinc\u00f3n", selected: "false", key: "location" },
  { id: 61, title: "R\u00edo Grande", selected: "false", key: "location" },
  { id: 62, title: "Sabana Grande", selected: "false", key: "location" },
  { id: 63, title: "Salinas", selected: "false", key: "location" },
  { id: 64, title: "San Germ\u00e1n", selected: "false", key: "location" },
  { id: 65, title: "San Juan", selected: "false", key: "location" },
  { id: 66, title: "San Lorenzo", selected: "false", key: "location" },
  { id: 67, title: "San Sebasti\u00e1n", selected: "false", key: "location" },
  { id: 68, title: "Santa Isabel", selected: "false", key: "location" },
  { id: 69, title: "Toa Alta", selected: "false", key: "location" },
  { id: 70, title: "Toa Baja", selected: "false", key: "location" },
  { id: 71, title: "Trujillo Alto", selected: "false", key: "location" },
  { id: 72, title: "Utuado", selected: "false", key: "location" },
  { id: 73, title: "Vega Alta", selected: "false", key: "location" },
  { id: 74, title: "Vega Baja", selected: "false", key: "location" },
  { id: 75, title: "Vieques", selected: "false", key: "location" },
  { id: 76, title: "Villalba", selected: "false", key: "location" },
  { id: 77, title: "Yabucoa", selected: "false", key: "location" },
  { id: 78, title: "Yauco", selected: "false", key: "location" }
];
