import React from "react";

export const ImageSVG = ({ api, type, secondary }) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={
          (type === api.currentPanel) && secondary
            ? "#1B4EA0"
            : type === api.currentPanel
              ? "#EC220D"
              : "#C5C6C7"
        }
        d="M20.9,2.2 C22.7225397,2.2 24.2,3.67746033 24.2,5.5 L24.2,20.9 C24.2,22.7225397 22.7225397,24.2 20.9,24.2 L5.5,24.2 C3.67746033,24.2 2.2,22.7225397 2.2,20.9 L2.2,5.5 C2.2,3.67746033 3.67746033,2.2 5.5,2.2 L20.9,2.2 Z M17.6,12.5556349 L8.154,22 L20.9,22 C21.5075132,22 22,21.5075132 22,20.9 L22,16.955 L17.6,12.5556349 Z M20.9,4.4 L5.5,4.4 C4.89248678,4.4 4.4,4.89248678 4.4,5.5 L4.4,20.9 C4.4,21.3720056 4.69728802,21.7745759 5.11483459,21.9306813 L16.8221825,10.2221825 C17.2517593,9.79260582 17.9482407,9.79260582 18.3778175,10.2221825 L18.3778175,10.2221825 L22,13.845 L22,5.5 C22,4.89248678 21.5075132,4.4 20.9,4.4 Z M9.35,6.6 C10.8687831,6.6 12.1,7.83121694 12.1,9.35 C12.1,10.8687831 10.8687831,12.1 9.35,12.1 C7.83121694,12.1 6.6,10.8687831 6.6,9.35 C6.6,7.83121694 7.83121694,6.6 9.35,6.6 Z M9.35,8.8 C9.04624339,8.8 8.8,9.04624339 8.8,9.35 C8.8,9.65375661 9.04624339,9.9 9.35,9.9 C9.65375661,9.9 9.9,9.65375661 9.9,9.35 C9.9,9.04624339 9.65375661,8.8 9.35,8.8 Z"
      />
    </svg>
  );
};
  
export const EditSVG = ({ api, type, secondary }) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={
          (type === api.currentPanel) && secondary
            ? "#1B4EA0"
            : type === api.currentPanel
              ? "#EC220D"
              : "#C5C6C7"
        }
        d="M10.274,3.3 C10.8815132,3.3 11.374,3.79248678 11.374,4.4 C11.374,5.00751322 10.8815132,5.5 10.274,5.5 L10.274,5.5 L4.4,5.5 C3.79248678,5.5 3.3,5.99248678 3.3,6.6 L3.3,6.6 L3.3,22 C3.3,22.6075132 3.79248678,23.1 4.4,23.1 L4.4,23.1 L19.8,23.1 C20.4075132,23.1 20.9,22.6075132 20.9,22 L20.9,22 L20.9,16.126 C20.9,15.5184868 21.3924868,15.026 22,15.026 C22.6075132,15.026 23.1,15.5184868 23.1,16.126 L23.1,16.126 L23.1,22 C23.1,23.8225397 21.6225397,25.3 19.8,25.3 L19.8,25.3 L4.4,25.3 C2.57746033,25.3 1.1,23.8225397 1.1,22 L1.1,22 L1.1,6.6 C1.1,4.77746033 2.57746033,3.3 4.4,3.3 L4.4,3.3 Z M20.5778175,1.42218254 L24.9778175,5.82218254 C25.4073942,6.25175926 25.4073942,6.94824074 24.9778175,7.37781746 L13.9778175,18.3778175 C13.7715274,18.5841075 13.4917381,18.7 13.2,18.7 L8.8,18.7 C8.19248678,18.7 7.7,18.2075132 7.7,17.6 L7.7,13.2 C7.7,12.9082619 7.81589252,12.6284726 8.02218254,12.4221825 L19.0221825,1.42218254 C19.4517593,0.99260582 20.1482407,0.99260582 20.5778175,1.42218254 Z M19.8,3.75563492 L9.9,13.6556349 L9.9,16.5 L12.7443651,16.5 L22.6443651,6.6 L19.8,3.75563492 Z"
      />
    </svg>
  );
};
  
export const LinkSVG = ({ type, api, secondary }) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={
          (type === api.currentPanel) && secondary
            ? "#1B4EA0"
            : type === api.currentPanel
              ? "#EC220D"
              : "#C5C6C7"
        }
        d="M6.32804155,10.7283236 C7.68187869,9.37399547 9.55719394,8.6744481 11.4672637,8.81124355 C13.3773335,8.948039 15.1338203,9.9076889 16.2808415,11.4411236 C16.644729,11.9275989 16.5453518,12.6169541 16.0588764,12.9808415 C15.5724011,13.344729 14.8830459,13.2453518 14.5191585,12.7588764 C13.7544776,11.7365867 12.5834864,11.0968201 11.3101066,11.0056231 C10.0367267,10.9144261 8.78651655,11.3807911 7.88381746,12.2838175 L7.88381746,12.2838175 L4.59721703,15.570183 C2.9297393,17.2966501 2.95358658,20.040956 4.65081529,21.7381847 C6.34804401,23.4354134 9.09234992,23.4592607 10.8051825,21.8051825 L10.8051825,21.8051825 L12.6861825,19.9241825 C13.1157593,19.4946058 13.8122407,19.4946058 14.2418175,19.9241825 C14.6713942,20.3537593 14.6713942,21.0502407 14.2418175,21.4798175 L14.2418175,21.4798175 L12.347183,23.374217 C9.75748232,25.8754336 5.64102345,25.8396627 3.09518038,23.2938196 C0.549337306,20.7479766 0.513566391,16.6315177 3.02818254,14.0281825 L3.02818254,14.0281825 Z M14.052817,3.02578297 C16.6425177,0.524566391 20.7589766,0.560337306 23.3048196,3.10618038 C25.8506627,5.65202345 25.8864336,9.76848232 23.3718175,12.3718175 L23.3718175,12.3718175 L20.0719585,15.6716764 C18.7181213,17.0260045 16.8428061,17.7255519 14.9327363,17.5887565 C13.0226665,17.451961 11.2661797,16.4923111 10.1191585,14.9588764 C9.75527104,14.4724011 9.8546482,13.7830459 10.3411236,13.4191585 C10.8275989,13.055271 11.5169541,13.1546482 11.8808415,13.6411236 C12.6455224,14.6634133 13.8165136,15.3031799 15.0898934,15.3943769 C16.3632733,15.4855739 17.6134835,15.0192089 18.5161825,14.1161825 L18.5161825,14.1161825 L21.802783,10.829817 C23.4702607,9.10334992 23.4464134,6.35904401 21.7491847,4.66181529 C20.051956,2.96458658 17.3076501,2.9407393 15.5925465,4.59708183 L15.5925465,4.59708183 L13.7005465,6.47808183 C13.2697192,6.90640432 12.5732407,6.90437377 12.1449182,6.47354647 C11.7165957,6.04271917 11.7186262,5.34624066 12.1494535,4.91791817 L12.1494535,4.91791817 Z"
      />
    </svg>
  );
};