// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:901ce7f1-bac8-42e8-b627-7d9349204839",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_JXVd8lfd4",
  aws_user_pools_web_client_id: "5ehri5bi2fes7nq2c8mcagg7s5",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://adl2ksacpzcu7m7so5aqywfdm4.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: "voz-de-pr-media91355-masterenv",
  aws_user_files_s3_bucket_region: "us-east-1",
};

export default awsmobile;
