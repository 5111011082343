import styled from "styled-components";

const SubjectDetailTitle = styled.p`
     /* border: 1px solid brown; */
    font-size: 24px;
    color: black;
    font-family: Avenir;

    margin: 0px;
    padding: 0px;
`;

export default SubjectDetailTitle;